<template>
  <div>
    <locked-field-v3 v-if="!hideLock" :value="extensionPlan.lockedFields" field="enabled" @input="lockedFieldHandler"/>
    <b-button-group class="btn-group-campaign-status" :disabled="true" :size="size">
      <b-button class="btn-campaign-status" :disabled="disabled" variant="stop" :class="{active: !extensionPlan.enabled}"
                v-p-tooltip.top="'Disable this extension'"
                @click="saveStatus(false)">
        <fluency-icon type="stop"></fluency-icon>
      </b-button>
      <b-button class="btn-campaign-status" :disabled="disabled" variant="play" :class="{active: extensionPlan.enabled}"
                v-p-tooltip.top="'Enable this extension'"
                @click="saveStatus(true)">
        <fluency-icon type="play"></fluency-icon>
      </b-button>
    </b-button-group>
  </div>

</template>

<script>
import LockedFieldV3 from '@/components/common/lockedFieldV3'

export default {
  name: 'extensionStatusButtonGroup',
  components: { LockedFieldV3 },
  props: {
    value: Object, // extensionPlan
    size: String,
    hideLock: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {}
  },
  computed: {
    extensionPlan () {
      return this.value
    }
  },
  methods: {
    lockedFieldHandler (lockedFields) {
      this.$emit('locked', {
        ...this.extensionPlan,
        lockedFields
      })
    },
    saveStatus (value) {
      if (this.disabled) return // if it is disabled don't set this

      let { lockedFields } = this.extensionPlan
      if (value !== this.extensionPlan.enabled) {
        lockedFields = LockedFieldV3.addLocks(this.extensionPlan, 'enabled')
      }
      this.$emit('input', {
        ...this.extensionPlan,
        enabled: value,
        lockedFields
      })
    }
  }
}
</script>

<style lang='scss' scoped>
.btn.disabled:not(.btn-link) {
  background-color:inherit;
  color:inherit;
  border-color:lighten($fluency-lightgray, 30);
}
.btn.disabled.btn-play.active {
  background-color: #b5cab4;
}
.btn.disabled.btn-stop.active {
  background-color: #ee7787;
}
</style>
